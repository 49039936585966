/* TableFrame.module.css */

.expandButtonContainer {
  z-index: 9999;
  position: absolute;
  top: 5px;
  right: 5px;
}

.expandButton {
  z-index: 9999;
  background-color: #ffffff;
  border: 0px solid #cccccc;
  border-radius: 50%;
  color: #333333;
  cursor: pointer;
  font-size: 8px;
  padding: 2px;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.expandButton:hover {
  background-color: #f0f0f0;
}

/* Modal */
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure backdrop is above other elements */
}

.modal {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Box shadow for depth */
  max-width: 300px;
  width: 100%;
  z-index: 1100; /* Ensure modal is above backdrop */
}

.modalHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  /* margin-bottom: 20px; */
  color: black;
}
.modalHeader h4 {
  margin-top: 5px;
  margin-bottom: 0px; /* Adjust the margin between headers as needed */
}
.modalHeader p {
  margin-top: 5px;
  margin-bottom: 0px; /* Adjust the margin between headers as needed */
}
.centered {
  display: flex;
  justify-content: center;
}


.modalHeader h2 {
  margin-top: 5px;
  margin-bottom: 0px; /* Adjust the margin between headers as needed */
}
.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #999;
}


.modalBody {
  max-height: 300px; /* Adjust the max height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  align-items: center;
}
.modalFooter {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.primaryButton,
.secondaryButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.primaryButton {
  background-color: #007bff;
  color: #fff;
  margin-left: 10px; /* Add space to the left of the secondary button */
}

.secondaryButton {
  background-color: #ccc;
  margin-right: 10px; /* Add space to the right of the primary button */
  color: #333;
}

/* TableFrame.module.css */
.quantityInput {
  outline: none;
  border: none;
  border-bottom: 1px solid skyblue; /* Adjust the color as needed */
  background-color: transparent;
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  width: 50px; /* Adjust the width as needed */
  font-size: 16px; /* Adjust font size as needed */
  color: black; /* Adjust text color as needed */
  text-align: center;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}
.tableFrame {
  position: relative;
}



.tableFrameChild {
  height: 120px;
  width: 327px;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--color-gray);
  box-shadow: 0 1px 1.3px rgba(0, 0, 0, 0.1);
  display: none;
}
.frameChild,
.frameItem {
  height: 110px;
  width: 110px;
  position: relative;
  border-radius: var(--br-10xs);
  background-color: var(--color-white);
  display: none;
}
.frameItem {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-steelblue);
  width: 19px;
  height: 19px;
  z-index: 2;
}
.doctorNameText {
  position: absolute;
  width: 17px; /* Adjust width */
  height: 17px; /* Adjust height */
  z-index: 7;
}

.imageRemovebgPreview541 {
  position: absolute;
  width: calc(100% - 2px);
  top: 8px;
  right: 0;
  left: 2px;
  max-width: 100%;
  overflow: hidden;
  height: 95px;
  object-fit: cover;
  z-index: 5;
}
.frameParent {
  height: 103px;
  flex: 1;
  position: relative;
}
.rectangleParent {
  width: 110px;
  border-radius: var(--br-10xs);
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-6xs) var(--padding-12xs)
    var(--padding-7xs);
  box-sizing: border-box;
  z-index: 3;
}
.examinationTable {
  position: relative;
  font-weight: 500;
  z-index: 3;
  max-width: 200px;
}
.brand {
  font-weight: 500;
}
.indoSurgicals {
  font-weight: 300;
}
.brandIndoSurgicalsContainer {
  position: relative;
  z-index: 3;
}
.quantity {
  font-weight: 500;
}
.span {
  font-weight: 300;
}
.quantity01Container {
  position: relative;
  white-space: pre-wrap;
  z-index: 3;
}
.groupIcon {
  height: 9.8px;
  width: 9.8px;
  position: relative;
  z-index: 4;
  /* margin-left: -38px; */
}
.quantity01Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-9xl) 0 0;
  color: var(--color-steelblue);
}
.totalAmt {
  font-weight: 500;
  /* font-size: 14px; */
}
.totalAmt1 {
  font-weight: 500;
  font-size: 10px;
}
.span1 {
  font-weight: 300;
}
.totalAmt643630Container {
  position: relative;
  z-index: 3;
}
.examinationTableParent,
.tableFrame {
  display: flex;
  justify-content: flex-start;
}
.examinationTableParent {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-4xs);
}
.tableFrame {
  width: 86vw;
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: #FAFAFA;
  box-shadow: 0 1px 1.3px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-8xs) var(--padding-xs) var(--padding-8xs) var(--padding-xs);
  gap: 5px 5px;
  z-index: 2;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-black);
  font-family: var(--font-inter);
}


.view2div {
  position: absolute;
  top: 138px;
  left: 348px;
  font-weight: 600;
}

.view2groupChild {
  position: absolute;
  top: 0;
  left: 3px;
  border-radius: 3px;
  border: 0.7px solid #d7d7d7;
  box-sizing: border-box;
  width: 179px;
  height: 178px;
}

.view2brand,
.view2specification {
  position: absolute;
  top: 44px;
  left: 192px;
  font-size: var(--font-size-xs);
  font-weight: 600;
  color: var(--color-darkgray-100);
}

.view2specification {
  top: 107px;
}

.view2skinStapler {
  margin: 0;
}

.view2skinStaplerRemoverContainer {
  position: absolute;
  top: 2px;
  left: 192px;
  font-weight: 600;
}

.view2indoSurgicals {
  position: absolute;
  top: 62px;
  left: 192px;
}

.view2seeMore1 {
  color: var(--color-steelblue);
}

.view2span {
  color: var(--color-black);
}

.view2seeMore {
  font-size: 11px;
}

.view2medscienceUltraNeoContainer {
  position: absolute;
  top: 125px;
  left: 192px;
}

.view2imageRemovebgPreview561 {
  position: relative;
  top: 5px;
  left: 5px;
  width: 174px;
  height: 170px;
  object-fit: cover;
}

.view2quantity {
  left: 211px;
  font-size: var(--font-size-xs);
  font-weight: 600;
  color: var(--color-darkgray-100);
}

.view2div1,
.view2ppuExclGst,
.view2quantity {
  position: absolute;
  top: 188px;
}

.view2ppuExclGst {
  left: 3px;
  font-size: var(--font-size-xs);
  font-weight: 600;
  color: var(--color-darkgray-200);
  white-space: pre-wrap;
}

.view2div1 {
  left: 135px;
}

.view2div2,
.view2ppuIncGst {
  position: absolute;
  top: 242px;
}

.view2ppuIncGst {
  left: 3px;
  font-size: var(--font-size-xs);
  font-weight: 600;
  color: var(--color-darkgray-200);
  white-space: pre-wrap;
}

.view2div2 {
  left: 135px;
}

.view2div3,
.view2totalAmount {
  position: absolute;
  top: 269px;
}

.view2totalAmount {
  left: 3px;
  font-size: var(--font-size-xs);
  font-weight: 600;
  color: var(--color-darkgray-200);
  white-space: pre-wrap;
}

.view2div3 {
  left: 135px;
}

.view2div4,
.view2groupItem,
.view2gst {
  position: absolute;
  top: 215px;
}

.view2gst {
  left: 3px;
  font-size: var(--font-size-xs);
  font-weight: 600;
  color: var(--color-darkgray-200);
  white-space: pre-wrap;
}

.view2div4,
.view2groupItem {
  left: 135px;
}

.view2groupItem {
  top: 208px;
  left: 211px;
  border-radius: 5px;
  background-color: var(--color-steelblue);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  width: 104px;
  height: 36px;
}

.view2div5,
.view2div6 {
  position: absolute;
  top: 208px;
  left: 210px;
  font-size: var(--font-size-xs);
  font-weight: 600;
  color: var(--color-white);
  cursor: pointer;
  width: 27px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view2div7 {
  /* Style the div to look like text */
  width: 35px;
  border: none;
  outline: none;
  cursor: text;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background-color: transparent;
}

.view2div6 {
  left: 290px;
}

.view2groupInner {
  padding: 0;
  position: absolute;
  top: 208px;
  left: 238px;
  background-color: #f1f1f1;
  width: 51px;
  height: 36px;
}

.view2div7 {
  position: absolute;
  top: 218px;
  left: 245px;
  font-size: var(--font-size-xs);
}

.view2rectangleDiv {
  position: absolute;
  top: 6px;
  left: 9px;
  border-radius: 1px;
  border: 0.7px solid #bbb;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
}

.view2groupIcon {
  position: absolute;
  top: 164px;
  left: 137px;
  width: 36.7px;
  height: 5.2px;
  object-fit: contain;
}

.view2rectangleParent {
  position: relative;
  width: 315px;
  height: 286px;
  font-size: var(--font-size-xs);
  color: var(--color-black);
}

.view2androidLarge2 {
  width: 360px;
  background-color: var(--color-white);
  height: 800px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-white);
  font-family: var(--font-inter);
}
/* Define the styles for the dots */
.dot {
  height: 5px;
  width: 5px;
  background-color: #bbb; /* Default color for inactive dots */
  border-radius: 50%;
  display: inline-block;
  margin: 0 2px;
  position: relative;
  z-index: 5;
  /* top:90px; */
}

.activeDot {
  background-color: #333; /* Color for the active dot */
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 2px;
  position: relative;
  z-index: 5;
  /* top:90px; */
}

.dotdiv {
  width: 100%;
  bottom:-90px;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
}
.dotdiv2 {
  width: 60%;
  bottom:10px;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
}