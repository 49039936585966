/* Add these styles to your existing CSS file */

.timer {
  margin-top: 10px;
  text-align: center;
}

.resendButton {
  background-color: white;
  color: black;
  border: 2px solid #04AA6D; /* Green */
  font-size:smaller;
  /* background-color: #007bff;
  color: #fff; */
  /* border: none; */
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.resendButton:hover {
  background-color: #0056b3;
}

.otpModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalBody {
  max-height: 300px; /* Adjust the max height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  align-items: center;
}
.modalBodyAttachment {
  max-height: 300px; /* Adjust the max height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  align-items: left;
}
/* Form container */
.otpFormContainer {
  align-items: center;
  justify-content: center;
  display: flex;
}

/* Individual OTP input */
.otpInput {
  width: 20px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
  margin: 0 5px; /* Adjust margin as needed */
  font-size: 16px; /* Adjust font size as needed */
  text-align: center;
}

/* Style for wrong OTP message */
.wrongOTPMessage {
  color: red;
}


.mainFrameChild {
  height: 19px;
  width: 700px;
  position: relative;
  background-color: var(--color-black);
  display: none;
}
.pm {
  position: relative;
  z-index: 1;
}
.labelFrameChild {
  height: 8px;
  width: 0;
  object-fit: contain;
}
.kbs,
.labelFrameChild {
  position: relative;
  z-index: 1;
}
.contentFrame,
.labelFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.labelFrame {
  padding: 0;
  gap: 7px;
}
.contentFrame {
  gap: 17px;
}
.groupIcon,
.vectorContainerIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 13px;
  height: 10px;
  z-index: 1;
}
.groupIcon {
  top: 2.3px;
  left: 23.8px;
  width: 14.2px;
  height: 7.9px;
}
.secondaryFrame {
  height: 10px;
  width: 38px;
  position: relative;
}
.mainFrame {
  align-self: stretch;
  background-color: var(--color-black);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-9xs) var(--padding-mini);
}
.totalAmountFrameChild {
  align-self: stretch;
  height: 1000px;
  position: relative;
  background-color: var(--color-white);
  display: none;
}
.yourQuotationDetails {
  margin: 0;
  position: relative;
  font-size: 17px;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
}
.doctorNameFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 18px;
}
.companyLogoFrameChild {
  align-self: stretch;
  height: 870px;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--color-white);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 0;
}
.name,
.span {
  font-weight: 600;
}
.drSunnyChoudhary1 {
  font-weight: 300;
}
.drSunnyChoudhary {
  color: var(--color-black);
}
.nameDrSunnyContainer {
  width: 205px;
  position: relative;
  display: inline-block;
  padding-right: var(--padding-xl);
  z-index: 2;
}
.dRNameFrame {
  align-self: stretch;
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-6xs);
  box-sizing: border-box;
}
.dateOfIssue {
  font-weight: 600;
}
.span2 {
  font-size: var(--font-size-sm);
}
.span1 {
  font-weight: 600;
  color: var(--color-black);
}
.dateOfIssueContainer {
  right:0;
  position: relative;
  z-index: 2;
}
.span3 {
  color: var(--color-black);
}
.refNumber {
  font-weight: 600;
}
.q031391 {
  font-weight: 300;
}
.q03139 {
  color: var(--color-black);
}
.refNumberQ03139Container {
  width: 140px;
  position: relative;
  display: inline-block;
  padding-right: var(--padding-xl);
  z-index: 2;
}
.issueDateLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.dividerLine {
  align-self: stretch;
  height: 0;
  position: relative;
  z-index: 2;
}
.assistanceFrame {
  width:auto;
  align-self: stretch;
  height: 55px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-6xs) 0 var(--padding-7xs);
  box-sizing: border-box;
  gap: 11px;
  color: var(--color-darkgray);
}
.tableFrameChild {
  height: 120px;
  width: 327px;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--color-gray);
  box-shadow: 0 1px 1.3px rgba(0, 0, 0, 0.1);
  display: none;
}
.frameChild,
.frameItem {
  border-radius: var(--br-10xs);
  display: none;
}
.frameChild {
  width: 110px;
  height: 110px;
  position: relative;
  background-color: var(--color-white);
  z-index: 0;
}
.frameItem {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-steelblue);
  width: 19px;
  height: 19px;
}
.rectangleGroup,
.vectorIcon {
  position: absolute;
  top: 6px;
  left: 4px;
  width: 11px;
  height: 7px;
  z-index: 5;
}
.rectangleGroup {
  width: 19px;
  height: 19px;
  margin: 0 !important;
  left: 6px;
  border-radius: var(--br-10xs);
  background-color: var(--color-steelblue);
  z-index: 4;
}
.imageRemovebgPreview871 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  z-index: 4;
}
.rectangleParent {
  align-self: stretch;
  width: 110px;
  border-radius: var(--br-10xs);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 26px var(--padding-3xs) var(--padding-7xs) var(--padding-mini);
  box-sizing: border-box;
  position: relative;
  z-index: 3;
}

.brand {
  font-weight: 500;
}
.indoSurgicals {
  font-weight: 300;
}
.brandIndoSurgicalsContainer {
  position: relative;
  z-index: 3;
}
.quantity {
  font-weight: 500;
}
.span4 {
  font-weight: 300;
}
.quantity01Container {
  position: relative;
  white-space: pre-wrap;
  z-index: 3;
}
.groupIcon1 {
  height: 9.8px;
  width: 9.8px;
  position: relative;
  z-index: 4;
  margin-left: -38px;
}
.quantity01Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-9xl) 0 0;
  color: var(--color-steelblue);
}
.totalAmt {
  font-weight: 500;
}
.span5 {
  font-weight: 300;
}
.totalAmt643630Container {
  position: relative;
  z-index: 3;
}
.tableFrame {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tableFrame {
  align-self: stretch;
  height: 120px;
  border-radius: var(--br-8xs);
  background-color: var(--color-gray);
  box-shadow: 0 1px 1.3px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  padding: var(--padding-8xs) var(--padding-33xl) var(--padding-8xs)
    var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-smi);
  z-index: 2;
  font-size: var(--font-size-sm);
  color: var(--color-black);
}

.productImageFrame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-4xs) 0 var(--padding-7xs);
}
.mainVectorIcon {
  width: 11px;
  height: 7px;
  position: absolute;
  margin: 0 !important;
  bottom: 154px;
  left: 30px;
  z-index: 3;
}
.blankLine,
.callUsAt,
.customerSupportTeam,
.forAnyAssistance,
.thankYouFor {
  margin: 0;
}
.forAnyAssistanceContainer {
  position: relative;
  font-weight: 600;
  z-index: 2;
}
.quotationAmountLabelChild {
  height: 53px;
  width: 342px;
  position: relative;
  background-color: var(--color-darkslategray);
  display: none;
}
.digitallySigned,
.noteThisIs {
  margin: 0;
}
.noteThisIsContainer {
  align-self: stretch;
  position: relative;
  font-weight: 300;
  z-index: 3;
}
.contactFrame,
.quotationAmountLabel {
  display: flex;
  align-items: center;
  text-align: center;
}
.quotationAmountLabel {
  height: 53px;
  background-color: var(--color-darkslategray);
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-mini) 31px var(--padding-sm) var(--padding-10xl);
  box-sizing: border-box;
  z-index: 2;
  font-size: var(--font-size-3xs);
  color: var(--color-white);
  font-family: var(--font-inter);
}
.contactFrame {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  color: var(--color-black);
}
.companyLogoFrame {
  position: relative;
  bottom: 0;
  background-color: var(--color-white);
}

.companyLogoFrame {
  min-height: 100vh;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-4xs) 0 0;
  box-sizing: border-box;
  gap: 20px;
  z-index: 1;
  text-align: left;
  font-size: var(--font-size-xs);
  color: #9d9c9c;
  font-family: var(--font-inter);
}
.quotationSummaryChild {
  align-self: stretch;
  width: 360px;
  border-radius: var(--br-8xs) var(--br-8xs) 0 0;
  box-shadow: 4px 0 4px rgba(0, 0, 0, 0.25);
  display: none;
}
.inr898765,
.yourQuotationAmount {
  /* font-weight: 800; */
  font-size: 80%;
  color: black;
  position: relative;
  text-align: center;
  font-weight: 300;
  z-index: 2;
}
.centeredContent {
  display: flex;
  align-items: center; /* Center items horizontally */
}
.yourQuotationAmount {
  font-size: x-small;
}
.inr898765 {
  font-size: large;
  font-weight: 800;
}
.yourQuota {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
}
.indoSurgicalsBrandChild {
  height: 39px;
  width: 209px;
  position: relative;
  border-radius: 0 var(--br-8xs) 0 0;
  background-color: var(--color-green);
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.25);
  display: none;
}
.raiseOrder {
  position: relative;
  z-index: 3;
}
.dullGreen {
  font-size: 12px !important;
background-color:rgb(165, 164, 164) !important;
}
.indoSurgicalsBrand,
.quotationSummary {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.indoSurgicalsBrand {
  left:-50%;
  border-radius: 6px;
  background-color: var(--color-green);
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.25);
  justify-content: center;
  padding: 15px 15px;
  z-index: 2;
  font-size: 12px;
  color: var(--color-white);
}
.quotationSummary {
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--br-8xs) var(--br-8xs) 0 0;
  box-shadow: 4px 0 4px rgba(0, 0, 0, 0.25);
  justify-content: space-between;
  padding-left: 5px;
  /* padding: 0 10px; */
  /* gap: 21px; */
  z-index: 100;
  font-size: var(--font-size-3xs);
  color: var(--color-darkslategray);
}

.home,
.totalAmountFrame {
  min-height:100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-family: var(--font-inter);
  /* background-image: url('https://i.postimg.cc/pV80LBBR/EQuotation-headar-06-2.png'); */
  background-color: var(--color-white); /* Fallback color */
  background-size:cover; /* Adjust background size as needed */

}
.totalAmountFrame {
  width: 100%;
  background-color: var(--color-white);
  align-items: center;
  /* padding: 24px 0 0; */
  gap: var(--gap-3xs);
  font-size: 24px;
  color: var(--color-black);
}
.home {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  overflow: hidden;
  align-items: flex-start;
  font-size: var(--font-size-4xs);
  color: var(--color-white);
}

/* Modal */
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure backdrop is above other elements */
}

.modal {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Box shadow for depth */
  max-width: 300px;
  width: 100%;
  z-index: 1100; /* Ensure modal is above backdrop */
}

.modalHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 20px; */
  color: black;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #999;
}

.modalBody {
  position: relative;
  margin-bottom: 20px;
  color: black;
}
.modalBody p {
  left: 0px;
  margin: 0px;
  margin-bottom: 5px;
  font-size: small;
  color: gray;
}
.modalBodyAttachment {
  position: relative;
  margin-bottom: 20px;
  color: black;
}
.modalBodyAttachment p {
  left: 0px;
  margin: 0px;
  margin-bottom: 5px;
  font-size: small;
  color: gray;
}
.modalFooter {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.primaryButton,
.secondaryButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.primaryButton {
  background-color: #007bff;
  color: #fff;
  margin-left: 10px; /* Add space to the left of the secondary button */
}

.secondaryButton {
  background-color: #ccc;
  margin-right: 10px; /* Add space to the right of the primary button */
  color: #333;
}


/* Styles for the download button */
.downloadButton {
  background-color: transparent;
  border: none;
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
}


/* Styles for the download button icon */
.downloadButton img {
  width: 20px;
  height: 20px;
}
.logo img {
  max-height: 50px; /* Limit the height of the logo */
  margin-left: 20px; /* Add some spacing to the right of the logo */
}
.phonecall {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
  width: 50px;
  height: 50px;
  right: 30px;
  bottom: 80px;
  background: black;
  position: fixed;
  text-align: center;
  color: #000000;
  cursor: pointer;
  border-radius: 50%;
  z-index: 99;
    display: inline-block;
    /* line-height: 65px; */

}
.phonecall img {
  max-width: 100%;
  max-height: 100%;
}
.phonetext { 
  width: 80px;
  right: 15px;
  bottom: 57px;
  position: fixed;
  text-align: center;
  color: #000000;
  cursor: pointer;
  z-index: 99;
    display: inline-block;
    font-weight: 700;
    text-align: center;

}
.phonetext1 { 
  width: 80px;
  font-weight: 400;
  right: 15px;
  bottom: 47px;
  position: fixed;
  text-align: center;
  color: #000000;
  cursor: pointer;
  z-index: 99;
    display: inline-block;
text-align: center;
  }
.td1 {
font-size: small;
}
.td2 {
  font-size: small;
  font-weight: 600;
  }

  .groupItem {
    cursor: pointer;
    border-radius: var(--br-8xs);
    background-color: #f9f9f9;
    border: 0.7px solid #d6d6d6;
    box-sizing: border-box;
    width: 50px;
    height: 31px;
    z-index: 10;
    font-size: x-small;
  }
  .groupItem:hover {
    background-color: rgb(223, 223, 223);
  }
  .groupItem1 {
    position: absolute;
    top: 5px;
    /* left: auto; */
    right: 0;
  }
  .groupItem1 {
    cursor: pointer;
    top: 3px;
    border-radius: var(--br-8xs);
    background-color: #f9f9f9;
    border: 0.7px solid #d6d6d6;
    box-sizing: border-box;
    width: 105px;
    height: 31px;
    z-index: 10;
    /* left:65%; */
    color: #000000;
  }
  .groupItem1:hover {
    background-color: rgb(223, 223, 223);
  }
  .downloadPdf {
    position: flex;
    /* top: 7px;
    left: 12px; */
    text-align: center;
  }
  .download1 {
    font-weight: 500;
  }
  .refNumber1 {
    font-weight: 600;
  }
  .loader {
    position: relative;
    max-height: 30px;
  }
  .discountTag {
    color: black;
    top: -10px;
    font-size: x-small;
    background-color: #ffa500;
    padding: 3px;
    border-radius: 8px;
  }
  .understoodButton:disabled {
    background-color: #d3d3d3;
    color: #a9a9a9;
    cursor: not-allowed;
}
.paymentTermsLinkk {
  color: blue;
  text-decoration: underline;
}
.paymentTermsLinkk:hover {
  color: #0056b3;
  cursor: pointer;
}
/* styles.upicard.css */

.upicard {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  cursor: pointer;
}

.upiLogo img {
  width: 50px; /* Adjust size as needed */
  margin-right: 20px;
}

.upiDetails {
  flex: 1;
}

.upiDetails h2 {
  color:#10b418;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 0px;
}

.upiDetails p {
  margin: 0;
  font-size: 10px;
  color: #555;
}
.upicard:hover {
  background-color:#e1dddd;
  box-shadow:#333;
}

.iconcontainer {
  position: relative;
}

.icon {
max-height: 25px;
}

.filecount {
  position: absolute;
  top: 15%;
  right: 25%;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.primaryButton:disabled {
  background-color: #ccc;
  color: white;
  cursor: not-allowed;
}
.disabled {
  opacity: 0.5; 
  pointer-events: none; 
  cursor: not-allowed; 
}
